import { CssBaseline, ThemeProvider } from "@mui/material";
import { useRoutes } from "react-router-dom";
import { dispatch, useSelector } from "src/store/Store";
import { ThemeSettings } from "./theme/Theme";
import RTL from "./layouts/full/shared/customizer/RTL";
import ScrollToTop from "./components/shared/ScrollToTop";
import Router from "./routes/Router";
import { AppState } from "./store/Store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import ServiciosNoEncontradosDialog from "./components/pages/login/ServiciosNoEncontradosDialog";
import { setConexionError } from "./store/customizer/CustomizerSlice";

const queryClient=new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 3600000, 
    },
  },
});


function App() {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const customizer = useSelector((state: AppState) => state.customizer);
  
  return (
    <QueryClientProvider client={queryClient}>    
      <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <CssBaseline />
        <ReactQueryDevtools initialIsOpen={false} />

        <ServiciosNoEncontradosDialog open={customizer.conexionError} closeModal={()=>{
          dispatch(setConexionError(false));
        }}></ServiciosNoEncontradosDialog>
        <ScrollToTop>{routing}</ScrollToTop>
      </RTL>
    </ThemeProvider>
    </QueryClientProvider>

  );
}

export default App;

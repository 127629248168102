import mock from '../mock';

import type { ContactType } from 'src/types/apps/contact';


export const ContactList: ContactType[] = [
  // {
  //   id: 1,
  //   firstname: 'Georgeanna',
  //   lastname: 'Ramero',
  //   image: user2,
  //   department: '123155325',
  //   company: 'Muller Inc',
  //   phone: '456-485-5623',
  //   email: 'qq739v47ggn@claimab.com',
  //   address: '19214 110th Rd, Saint Albans, NY, 1141',
  //   notes: chance.paragraph({ sentences: 2 }),
  //   frequentlycontacted: true,
  //   starred: true,
  //   deleted: false,
  // },
 
  
];

mock.onGet('/api/data/contacts/ContactsData').reply(() => {
  const contacts = ContactList;

  return [200, JSON.parse(JSON.stringify(contacts))];
});
export default ContactList;

import { createSlice } from '@reduxjs/toolkit';
import { ITokenDecoded } from 'src/components/apps/ecommerce/productGrid/TokenProtocoloDialogValidate';

import { ProductType } from 'src/types/apps/eCommerce';

interface StateType {
  products: ProductType[];
  productSearch: string;
  sortBy: string;
  cart: any[];
  added: boolean;
  total: number;
  filters: {
    category: string;
    color: string;
    gender: string;
    price: string;
    rating: string;
  };
  selectedUser: {
    idIMO: string;
    category: string;
  };
  error: string;
  ticketId: string;
  noProduct: boolean;
  tokenProtocolo: string;
  isProtocoloCommerce: boolean;
  infoProtocolo?: ITokenDecoded | null;
}

const initialState = {
  products: [],
  productSearch: '',
  sortBy: 'newest',
  cart: [],
  added: false,
  total: 0,
  filters: {
    category: 'TODOS',
    color: 'TODOS',
    gender: 'TODOS',
    price: 'TODOS',
    rating: '',
  },
  selectedUser: {
    idIMO: '',
    category: '',
  },
  error: '',
  ticketId: '',
  noProduct: false, // No hay producto en el inventario
  isProtocoloCommerce: false,
  tokenProtocolo: '',
  infoProtocolo: null,
};

export const EcommerceSlice = createSlice({
  name: 'ecommerce',
  initialState,
  reducers: {
    setProtocoloCommerce(state: StateType, action) {
      state.isProtocoloCommerce = action.payload;
    },
    // HAS ERROR
    setTotal(state: StateType, action) {
      state.total = action.payload;
    },
    hasError(state: StateType, action) {
      state.error = action.payload;
    },
    setTokenProtocolo(state: StateType, action) {
      state.tokenProtocolo = action.payload;
    },
    setTicketId(state: StateType, action) {
      state.ticketId = action.payload;
    },
    setInfoProtocolo(state: StateType, action) {
      state.infoProtocolo = action.payload;
    },
    setNoProduct(state: StateType, action) {
      state.noProduct = action.payload;
    },
    // GET PRODUCTS
    getProducts: (state, action) => {
      state.products = action.payload;
    },
    SearchProduct: (state, action) => {
      state.productSearch = action.payload;
    },

    //  SORT  PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    //  SORT  PRODUCTS
    sortByGender(state, action) {
      state.filters.gender = action.payload.gender;
    },

    //  SORT  By Color
    sortByColor(state, action) {
      state.filters.color = action.payload.color;
    },

    //  SORT  By Color
    sortByPrice(state, action) {
      state.filters.price = action.payload.price;
    },

    //  FILTER PRODUCTS
    filterProducts(state, action) {
      state.filters.category = action.payload.category;
    },

    //  FILTER Reset
    filterReset(state) {
      state.filters.category = 'TODOS';
      state.filters.color = 'TODOS';
      state.filters.gender = 'TODOS';
      state.filters.price = 'TODOS';
      state.sortBy = 'newest';
      state.productSearch = '';
    },
    addToCart(state: StateType, action) {
      const product = action.payload;
      const cartIndex = state.cart.findIndex((item) => item.display_name === product.display_name);
    
      // Si es un protocolo, no se verifica el inventario
      if (state.isProtocoloCommerce) {
     
        if (cartIndex !== -1) {
          // Producto ya existe en el carrito, simplemente aumentar la cantidad
          state.cart[cartIndex].qty += 1;
        } else {
          // Producto nuevo en el carrito
          state.cart.push({
            ...product,
            qty: 1,
            selectedLots: [], // No hay lotes ya que es un protocolo
          });
        }
      } else {
     
        // Si no es protocolo, se verifica el inventario
        if (cartIndex !== -1) {
          if (state.cart[cartIndex].inventory.length > 0) {
            let neededQty = state.cart[cartIndex].qty + 1;
            let availableQty = 0;
            let selectedLots = [];
    
            for (let element of state.cart[cartIndex].inventory) {
              if (availableQty < neededQty) {
                let qtyToAdd = Math.min(element.quantityonhand, neededQty - availableQty);
                availableQty += qtyToAdd;
                selectedLots.push({ loteName: element.inventorynumber_name, qty: qtyToAdd });
    
                if (availableQty >= neededQty) {
                  break;
                }
              }
            }
    
            if (availableQty >= neededQty) {
              state.cart[cartIndex].qty = neededQty;
              state.cart[cartIndex].selectedLots = selectedLots;
            } else {
              state.noProduct = true;
            }
          } else {
            state.cart[cartIndex].qty += 1;
          }
        } else {
          let initialLots = [];
          if (product.inventory && product.inventory.length > 0) {
            let qtyToAdd = Math.min(product.inventory[0].quantityonhand, 1);
            initialLots.push({ loteName: product.inventory[0].inventorynumber_name, qty: qtyToAdd });
            state.cart.push({
              ...product,
              qty: 1,
              selectedLots: initialLots,
            });
          } else {
            if (product.type === 'InvtPart') {
              state.noProduct = true;
            } else {
              state.cart.push({
                ...product,
                qty: 1,
                selectedLots: [],
              });
            }
          }
        }
      }
    },

    decrement(state: StateType, action) {
      const { display_name } = action.payload; // Producto

      const cartIndex = state.cart.findIndex((item) => item.display_name === display_name);
      if (cartIndex !== -1) {
        const cartItem = state.cart[cartIndex];
        const newQty = cartItem.qty - 1; // Disminuimos la cantidad del producto en el carrito

        if (cartItem.inventory && cartItem.inventory.length > 0 && newQty >= 0) {
          // El producto es inventariable y hay un inventario asociado
          if (cartItem.selectedLots && cartItem.selectedLots.length > 0) {
            // Hay lotes seleccionados, actualizamos el último lote añadido
            let lastLot = cartItem.selectedLots[cartItem.selectedLots.length - 1];
            if (lastLot.qty > 1) {
              // Si el último lote tiene más de 1, simplemente disminuye su cantidad
              lastLot.qty -= 1;
            } else {
              // Si el último lote solo tiene 1, elimina el lote completo
              cartItem.selectedLots.pop();
            }
          }

          // Actualizamos la cantidad del producto en el carrito
          state.cart[cartIndex] = { ...cartItem, qty: newQty, selectedLots: cartItem.selectedLots };
        } else if (newQty >= 0) {
          // Producto no inventariable o no tiene inventario asociado
          state.cart[cartIndex] = { ...cartItem, qty: newQty };
        }

        // Eliminar el producto del carrito si la cantidad es 0
        if (newQty === 0) {
          state.cart.splice(cartIndex, 1);
        }
      }
    },

    // delete Cart
    deleteCart() {
      //Regresamos todos los productos al stock
    },
    clearCart(state: StateType) {
      state.cart = [];
      state.tokenProtocolo = '';
    },

    //selected User
    selectedUser(state: StateType, action) {
      state.selectedUser = action.payload;
    },
  },
});
export const {
  hasError,
  getProducts,
  SearchProduct,
  sortByProducts,
  setTokenProtocolo,
  filterProducts,
  sortByGender,
  deleteCart,
  setInfoProtocolo,
  clearCart,
  decrement,
  addToCart,
  setProtocoloCommerce,
  setNoProduct,
  sortByPrice,
  setTicketId,
  filterReset,
  setTotal,
  sortByColor,
  selectedUser,
} = EcommerceSlice.actions;

// export const fetchProducts = () => async (dispatch: AppDispatch) => {
//   try {
//     const response = await axios.get(`${API_URL}`);
//     dispatch(getProducts(response.data));
//   } catch (error) {
//     dispatch(hasError(error));
//   }
// };

export default EcommerceSlice.reducer;

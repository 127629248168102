import mock from './mock';

import './contacts/ContactsData';


import './ticket/TicketData';
import './eCommerce/ProductsData';




mock.onAny().passThrough();

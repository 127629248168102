import axios from '../../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ContactType } from 'src/types/apps/contact';

const API_URL = '/api/data/contacts/ContactsData';

interface StateType {
  contacts: ContactType[];
  contactContent: number;
  contactSearch: string;
  editContact: boolean;
  currentFilter: string;
}

const initialState = {
  contacts: [],
  contactContent: 0,
  contactSearch: '',
  editContact: false,
  currentFilter: 'show_all',
};

export const ContactSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    getContacts: (state: StateType, action) => {
      state.contacts = action.payload;
    },
    SearchContact: (state: StateType, action) => {
      state.contactSearch = action.payload;
    },
    SelectContact: (state: StateType, action) => {
      state.contactContent = action.payload;
    },
    DeleteContact: (state: StateType, action) => {
      state.contacts = state.contacts.map((contact) =>
        contact.id === action.payload ? { ...contact } : contact,
      );
    },
    toggleStarredContact: (state: StateType, action) => {
      state.contacts = state.contacts.map((contact) =>
        contact.id === action.payload ? { ...contact } : contact,
      );
    },
    isEdit: (state: StateType) => {
      state.editContact = !state.editContact;
    },
    setVisibilityFilter: (state: StateType, action) => {
      state.currentFilter = action.payload;
    },

    UpdateContact: {
      reducer: (state: StateType, action: PayloadAction<any>) => {
        state.contacts = state.contacts.map((contact) =>
          contact.id === action.payload.id
            ? { ...contact, [action.payload.field]: action.payload.value }
            : contact,
        );
      },
      prepare: (id, field, value) => {
        return {
          payload: { id, field, value },
        };
      },
    },
    addContact: {
      reducer: (state: StateType, action: PayloadAction<any>) => {
        // Busca si ya existe un contacto con el mismo ID
        const index = state.contacts.findIndex(contact => contact.id === action.payload.id);
    
        if (index === -1) {
          // Si no existe, agrega el nuevo contacto
          state.contacts.push(action.payload);
        } else {
          // Opcional: Si el contacto ya existe, puedes optar por actualizarlo
          // state.contacts[index] = { ...state.contacts[index], ...action.payload };
        }
      },
      prepare: (
        id,
        name,
        date,
        adress,
        category,
        image,
       
      ) => {
        return {
          payload: {
            id,
            name,
            date,
            adress,
            category,
            image,
           
          },
        };
      },
    },
  },
});

export const {
  getContacts,
  SearchContact,
  isEdit,
  SelectContact,
  DeleteContact,
  toggleStarredContact,
  UpdateContact,
  addContact,
  setVisibilityFilter,
} = ContactSlice.actions;

export const fetchContacts = () => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${API_URL}`);
    dispatch(getContacts(response.data));
  } catch (err: any) {
    throw new Error(err);
  }
};

export default ContactSlice.reducer;


import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Box,

} from '@mui/material';
import NubeImg from 'src/assets/images/backgrounds/nube.png';

interface SimpleDialogProps {
    open: boolean;
    closeModal: any;
}

export default function ServiciosNoEncontradosDialog({open,closeModal}:SimpleDialogProps) {

    const handleClose = () => {
        closeModal();
      };

  return (
<>

      <Dialog fullWidth={true} maxWidth={"sm"} onClose={() =>{}} open={open}>

      <DialogTitle>Sin conexion</DialogTitle>
        <DialogContent>
          <DialogContentText>
           Lo sentimos no pudimos tenemos problemas para encontrar nuestros servicios, intanta de nuevo mas tarde...
          </DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
           <img src={NubeImg} alt="404" style={{ width: '100%', maxWidth: '300px' }} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={handleClose}>
            Cerrar
          </Button>
        </DialogActions>
     
      </Dialog>
</>
  )
}

import { useMutation, useQuery } from '@tanstack/react-query';
import axiosServices from '../axios';
interface dataPostI {
  message: any;
  status: number;
}
interface PostResponse {
  data: dataPostI;
}
export const postProductosCompra = async (compra: ICompra) => {
  return await axiosServices.post(`/productos/comprar`, compra);
};

export const postProductosCompraAgendaPro = async (compra: ICompra) => {
  return await axiosServices.post(`/productos/agendapro`, compra);
};
export const getProductos = async (locationid: string): Promise<PostResponse> => {
  return await axiosServices.get(`/productos/${locationid}`);
};
export const getTicketPDF = async (ticketId: string): Promise<PostResponse> => {
  return await axiosServices.post(`/productos/ticket`, { ticketId: ticketId });
};

export const obtenerProductosMutation = (locationid: string) => {
  return useQuery({
    queryFn: async () => {
      return getProductos(locationid);
    },
    queryKey: ['productos', locationid],
    enabled: locationid !== '' && locationid !== undefined && locationid !== null,
    retry: 3,
  });
};

export const comprarProductosMutation = () => {
  return useMutation({
    mutationFn: async (compra: ICompra) => postProductosCompra(compra),
    mutationKey: ['comprarProductos'],
    retry: 0,
  });
};

export const comprarProductosAgendaProMutation = () => {
  return useMutation({
    mutationFn: async (compra: ICompra) => postProductosCompraAgendaPro(compra),
    mutationKey: ['comprarProductosAgendaPro'],
    retry: 3,
  });
};
export const getTicketPDFMutation = () => {
  return useMutation({
    mutationFn: async (ticketId: string) => getTicketPDF(ticketId),
    mutationKey: ['getTicketPDF'],
    retry: 0,
  });
};

export interface paymentMethods {
  amount: number;
  methodId: number;
  cardNumber: string;
  autorizationNumber: string;
}
export interface IProducts {
  productId: number;
  quantity: number;
  price: number;
  item_type: string;
  id_lote: string;
  nombre: string;
  location_id_line: number;
}
export interface ICompra {
  cashierId: number;
  IdLocation: number;
  clientId: number;
  userCategory: string;
  total: number;
  paymentMethods: Array<paymentMethods>;
  productList: Array<IProducts>;
  tokenProtocolo?: string;
  idUsuario: number;
}
